<template>
  <Toast/>
  <div class="container" @click="onWrapperClick">

    <div class="layout-form-container">
      <div class="layout-main">
        <Card>
          <template #title>
            Create New App
          </template>
          <template #content>
            <div class="grid p-fluid">
              <div class="col-12 md:col-4">
                <div class="field">
                  <Dropdown v-model="selectedAccount" :options="accounts"
                            optionLabel="accountName" placeholder="Select Account"/>
                </div>
              </div>
            </div>
            <div class="grid p-fluid">
              <div class="col-12 md:col-4">
                <div class="field">
                    <label for="appName">App Name</label>
                  <InputText id="appName" type="text" v-model="appName"
                             aria-describedby="appName-help"></InputText>
                  <small id="appName-help">App can be custom app name given. Ex: iyiapp, guzelapp</small>
                </div>
              </div>
            </div>
            <div class="grid p-fluid">
              <div class="col-12 md:col-4">
                <div class="field">
                  <label for="packageName">Package Name</label>
                  <InputText id="packageName" type="text" v-model="packageName"
                             aria-describedby="packageName-help"></InputText>
                  <small id="packageName-help">Package name should be official app package name. Ex: com.xxx.yyy</small>
                </div>
              </div>
            </div>
          </template>

          <template #footer>
            <Button icon="pi pi-check" label="Create" @click="createApp()"/>

            <Button icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em"
                    @click="cancelCreate()"/>
          </template>
        </Card>

      </div>
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import router from "@/router";
import AccountService from "@/service/AccountService";
import {creds} from "@/states/Cred";
import PlaystoreAppService from "@/service/PlaystoreAppsService";

export default {
  name: "CreateAccount",
  computed: {
  },
  created() {
    this.accountService = new AccountService();
    this.appService = new PlaystoreAppService();
  },
  mounted() {
    this.accountService.getAccounts().then(accounts => this.accounts = accounts)
  },
  data() {
    return {
      creds,
      accountService: null,
      appService: null,
      accounts: null,
      selectedAccount: null,
      appName: null,
      packageName: null,

    }
  },

  methods: {
    createApp() {
      const request = {
        "appName": this.appName,
        "packageName": this.packageName,
        "accountId": this.selectedAccount._id

      }
      this.appService.createApp(request)
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'App Creation Failed',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'App Created',
                life: 3000
              });

              this.$emit('appChanged', event)
              router.push("/")
            }
          });
    },

    cancelCreate() {
      router.push("/")
    }
  },
  components: {
  }

}
</script>

<style scoped>

</style>
